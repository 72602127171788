<template>
  <v-card>
    <LoadingModal :visible="loading" />

    <v-card-title>
      <span class="headline">
        {{ data.id ? "Editar cliente" : "Cadastrar cliente" }}
      </span>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-container>
        <v-form>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :rules="requiredRule"
                :autofocus="true"
                label="Nome Cliente*"
                :disabled="enabledInputs"
                v-model="data.name"
                :error-messages="errors.name"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="usersAdms"
                :rules="requiredRule"
                item-text="name"
                item-value="id"
                v-model="data.user_id"
                :error-messages="errors.user_id"
                label="Responsavel"
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="CNPJ"
                :disabled="enabledInputs"
                v-model="data.cnpj"
                :error-messages="errors.cnpj"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                :rules="requiredRule"
                label="Administradora"
                :disabled="enabledInputs"
                v-model="data.nome_administradora"
                :error-messages="errors.nome_administradora"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                :rules="requiredRule"
                prepend-icon=""
                :autofocus="true"
                label="E-Mail"
                :disabled="enabledInputs"
                v-model="data.email_administradora"
                :error-messages="errors.email_administradora"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                prepend-icon=""
                :autofocus="true"
                label="E-mails adicionais "
                :disabled="enabledInputs"
                v-model="data.emails_adicionais"
                :error-messages="errors.emails_adicionais"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-select
                multiple
                :rules="requiredRule"
                :items="usersResponsible"
                item-text="name"
                item-value="id"
                v-model="data.responsibles"
                :error-messages="errors.responsibles"
                label="Usuario Responsaveis"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        outlined
        class="no-text-transform"
        color="primary"
        @click="cancel()"
      >
        Voltar
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn class="no-text-transform" color="primary" @click="saveData()">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ToastService from "@/services/ToastService";
import ClienteService from "@/services/Cliente/ClienteService";
import instanceAxios from "../../plugins/axios";

export default {
  name: "UserForm",

  mounted: async function () {
    const id = this.$route.params.id;
    if (id !== undefined) {
      this.loading = true;
      await this.showData(id);
    }

    this.getUsers();
  },

  data: () => ({
    data: {},
    errors: {},
    users: [],
    listRoles: [],
    loading: false,
    enabledInputs: false,
    ClienteService: new ClienteService(""),
  }),

  computed: {
    requiredRule: [(v) => !!v || "Campo é obrigatorio"],
    usersAdms: function () {
      return this.users.filter(
        (el) =>
          (el.role.id == 5 && el.customer_id == null) ||
          el.id == this.data.user_id
      );
    },

    usersResponsible: function () {
      return this.users.filter((el) => el.role.type == "responsible");
    },
  },

  methods: {
    async getUsers() {
      const { data } = await instanceAxios.get("users");
      this.users = data;
    },
    async saveData() {
      try {
        this.loading = true;
        this.enabledInputs = true;

        const id = this.data.id !== undefined ? "/" + this.data.id : "";

        await this.ClienteService.storeOrUpdate("customers" + id, this.data);

        ToastService("Ação realizada com sucesso.", "success");

        await this.$router.push({ path: "/app/clientes" });
      } catch (exception) {
        console.log(exception);
        if (typeof exception === "object") {
          this.errors = exception;
          ToastService("Verifique o preenchimento dos dados.", "error");
        } else {
          ToastService(exception, "error");
        }
      } finally {
        this.loading = false;
        this.enabledInputs = false;
      }
    },

    async showData(id) {
      try {
        this.data = await this.ClienteService.show("customers/" + id);
      } catch (exception) {
        ToastService("Não foi possível consultar os dados.", "error");

        this.cancel();
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$router.push({ path: "/app/clientes" });
    },
  },
};
</script>
